.table_container{
    position: relative;
    }
    .tittle_img{
        width: 900px;
        margin: 80px;
    }
    .second_tittle{
        font-family: 'Poppins', sans-serif;
        font-weight: 900;
        font-size: 35px;
        color: #021187;
    }
    .mobile_number_list{
        display: none;
    }
    .banner_tittle_container{
        display: flex;
        justify-content: space-between;
        margin: 0px 120px;
        width: 500px;
    }
    .banner_parrafo{
        display: flex;
    }
    .banner_container{
        height: auto;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 55%;
        top: 12%;
    }
    .banner_container p{
        color: #021187;
        font-weight: 800;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
        font-size: 20px;
        line-height: 94px;
    }
    .banners_img{
        width: 200px;
        margin: 0px 15px
    }
    .sin_limites{
        color: #021187;
        font-family: 'Poppins', sans-serif;
        font-size: 25px;
        line-height: 0;
    }
    .charge_number{
        color: #021187;
        font-family: 'Poppins', sans-serif;
        font-size: 28px;
        line-height: 0;
    }
    .plan_container{
        text-align: start;
        margin: 0px 120px;
        font-family: 'Poppins', sans-serif;
        font-weight: 100;  
        display: flex;
        justify-content: space-between;
    }
    .plan_box{    
        position: relative;
        line-height: 20px;
        display: flex;
    }  
    .plan_box hr{
        position: absolute;
        width: calc(70% + 1300px);
    }
    .plan_box h4{
        color: #021187;
        font-size: 20px;
        height: auto;
    }
    .plan_box h5{
        color: #5A5A5C;
        font-size: 18px;    
    }
    .number_box p{
        color: #021187;
        font-weight: 900;    
    }
    hr{
        border: none;
        border-top: 1px solid #9d9d9d9f;
    }
    .mobile_list{
        display: none;
    }
    /* CONTRATAR BUTTON*/ 
    .contract_container{
        display: flex;
        justify-content: flex-end;
    }
    .contract{    
        color: #021187;
        background: #2AFEB2;
        border-radius: 28px;
        height: 55px;
        line-height: 53px;
        width: 180px;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.348);    
        font-family: 'Poppins', sans-serif;
        cursor: pointer;
    }
    .contract:hover{    
        box-shadow: inset 7px 3px 3px rgba(0, 0, 0, 0.285);
        transition: 1s;
    }
    .call_text{
        font-family: 'Poppins', sans-serif;
        color: #021187;
        font-size: 32px;
        margin-top: 260px;
        cursor: pointer;
    }
    .call_text:hover{
        text-decoration: underline;
    }
    .number_column{
        border: solid 3px #2AFEB2;
        border-radius: 40px;
    }
    
    /* RESPONSIVE */ 
    .second_tittle {
        display: block;
      }
    .plan_container_mobile{
        display: none;
    }
    .plan_box_mobile_1{
        background-color: #b2b4d4;
        border-radius: 30px;
    }
    .plan_box_mobile_2{
        background-color:  #2AFEB2;
        border-radius: 30px;
    }
    .plan_box_mobile_3{
        background-color:  #021187;
        border-radius: 30px;
    }
    .plan_box_tittle_1{
        color: white;
        font-weight: 900;
        text-align: center;
        font-size: 40px;
        padding-top: 30px;
    }
    .plan_box_tittle_2{
        color: #021187;
        font-weight: 900;
        text-align: center;
        font-size: 40px;
        padding-top: 30px;
    }
    .plan_box_tittle_3{
        color: #2AFEB2;
        font-weight: 900;
        text-align: center;
        font-size: 40px;
        padding-top: 30px;
    }
    .plan_box_price{
        text-align: center;
        font-size: 50px;
        font-weight: 900;
        color: #021187;
        line-height: 1px;
    }
    .plan_box_mobile_1 p{
        color: #021187;
        text-align: center;
        line-height: 1px;
    }
    .plan_box_mobile_2 p{
        color: #021187;
        text-align: center;
        line-height: 1px;
    }
    .plan_box_mobile_3 p{
        color: #2AFEB2;
        text-align: center;
        line-height: 1px;
    }
    .box_items{
        display: flex;
    }
    .box_items_h4{
        margin-left: 40px;
        line-height: 19px;
    }
    .box_items_h4 h4{
        font-size: 19px;
    }
    .number_items{
        margin-left: 40px;
        padding-right: 20px;
    }
    .contratar_btn_1{
        background-color: #2AFEB2;
        color: #021187;
        border-radius: 20px;
        width: 180px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        align-items: center;
    }
    .contratar_btn_2{
        color: #2AFEB2;
        background-color: #021187;
        border-radius: 20px;
        width: 180px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        align-items: center;
    }
    .contratar_btn_3{
        background-color: #2AFEB2;
        color: #021187;
        border-radius: 20px;
        width: 180px;
        text-align: center;
        height: 50px;
        line-height: 50px;
        align-items: center;
    }
    .btn_text_mobile{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }
    @media (max-width: 1700px){
        .plan_box hr{
            position: absolute;
            width: calc(70% + 1000px);
        }
        .banner_container{
            left: 45%;
        }
    }
    @media (max-width: 1400px){
        .plan_box hr{
            position: absolute;
            width: calc(70% + 750px);
        }
        .banner_container{
            left: 35%;
        }
    }
    @media (max-width: 1200px){
        .plan_box hr{
            position: absolute;
            width: calc(70% + 500px);
        }
        .banner_container{
            left: 35%;
        }
        .call_text{
            margin-top: 350px;
        }
    }
    @media (max-width: 1100px){
        .plan_container{
            margin: 0 40px;
        }
        .banner_container{
            left: 25%;
            top: 10%;
        }
        .tittle_img{
            width: 700px;
        }
        .banner_tittle_container{
            margin: 0 20px;
            font-size: 20px;
        }
        .banner_tittle_container h2{
            font-size: 30px;
        }
    }
    @media (max-width: 900px) {   
        .call_text{
            display: none;
        }
        .plan_container_mobile{
            display: block;
            margin-left: 180px;
        }
        .mobile_number_list{
            display: block;
            position: absolute;
            left: 190px;
            top: -200px;
            display: flex;
            flex-direction: column;
        }
        .mobile_number_list p{
            line-height: 95px;
        }
        .number_column p{
            display: none;
        }
        .number_column h4{
            display: none;
        }
        .number_column h3{
            display: none;
        }
        .number_column{
            border: solid transparent;
        }
        .first_number_list p{
            display: none;
        }
        .first_number_list h4{
            display: none;
        }
        .first_number_list h3{
            display: none;
        }
        .third_number_column p{
            display: none;
        }
        .third_number_column h4{
            display: none;
        }
        .third_number_column h3{
            display: none;
        }
        .second_tittle {
          display: none;
        }
        .second_tittle.mobile-text {
          display: block;
          font-size: 25px;
          margin-left: 40px;
        }
        .mobile_list{
            display: block;
        }
        .desktop_list{
            display: none;
        }
        .plan_container{
    
            margin: 50px 50px;
        }
        .second_tittle{
           position: absolute;
    
           left: 2%;
        }
        .plan_box hr{
            width: calc(40% + 500px);
        }
        .banner_container p{
            font-size: 18px;
        }
        .banner_container{
            left: 43%;
        } 
        .banners_img{
            display: none;
        }
        .tittle_img{
            width: 600px;
            margin: 0;
        }
    }
    @media (max-width: 800px) {
        .plan_box hr{
            position: absolute;
            width: calc(50% + 350px);
        }
    }
    @media (max-width: 680px) {
        .plan_box hr{
            position: absolute;
            width: calc(50% + 300px);
        }
        .tittle_img{
            width: 430px;
            margin-top: 40px;
        }
        .plan_container_mobile{
            margin-left: 80px;
        }
    }
    @media (max-width: 600px) {
        .plan_box hr{
            position: absolute;
            width: calc(50% + 230px);
        }
    }
    @media (max-width: 500px) {
        .tittle_img{
            width: 280px;
        }
        .mobile_number_list p{
            font-size: 13px;
            line-height: 50px;
        }
        .plan_box h4{
            font-size: 13px;
        }
        .plan_box h5{
            font-size: 9px;
            line-height: 0;
        }
        .plan_box hr{
            position: absolute;
            width: calc(50% + 160px);
        }
        .second_tittle.mobile-text{
            font-size: 18px;
        }
        .mobile_number_list{
            left: 110px;
            top: -110px;
        }
        .plan_box_tittle_1{
            font-size: 25px;
        }
        .plan_box_tittle_2{
            font-size: 25px;
        }
        .plan_box_tittle_3{
            font-size: 28px;
        }
        .plan_box_price{
            font-size: 28px;
        }
        .box_items_h4 h4{
            font-size: 14px;
        }
        .plan_container_mobile{
            margin-left: 0px;
        }
    }
    @media (max-width: 390px){
        .plan_box hr{
            position: absolute;
            width: calc(50% + 130px);
        }
        .mobile_number_list{
            top: -106px;
        }
        .mobile_number_list p{
            line-height: 54px;
        }
        .box_items_h4 h4{
            font-size: 10px;
        }
    }
    @media (max-width: 340px){
        .second_tittle.mobile-text {
            font-size: 15px;
          }
        .plan_container{
            margin: 20px 20px;
        }
        .mobile_number_list{
            top: -108px;
            left: 80px
        }
        .box_items_h4 h4{
            font-size: 7px;
        }
        .number_items{
            font-size: 12px;
        }
        .plan_box_price{
            font-size: 20px;
        }
        .plan_box_tittle_1{
            font-size: 20px;
        }
        .plan_box_tittle_2{
            font-size: 20px;
        }
        .plan_box_tittle_3{
            font-size: 20px;
        }
        .contratar_btn_1{
            font-size: 14px;
            width: 140px;
        }
        .contratar_btn_2{
            font-size: 14px;
            width: 140px;
        }
        .contratar_btn_3{
            font-size: 14px;
            width: 140px;
        }
        .plan_box_mobile_1 p{
            font-size: 13px;
        }
        .plan_box_mobile_2 p{
            font-size: 13px;
        }
        .plan_box_mobile_3 p{
            font-size: 13px;
        }
    }