.fourthstep1{
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
 }
 .fourthstep{
     display: flex;
     justify-content: space-evenly;
     margin-top: 100px; display: flex;
     flex-direction: column;
 }
 .final_step_container{
     color: #021187;
     font-family: 'Poppins', sans-serif;
     display: flex;
     justify-content: center;
 }
 .text_banner{
     align-items: center;
     justify-content: space-around;
 }
 .final_step_container h2{
     font-size: 40px;
 }
 .final_step_container p{
     font-size: 30px;
 }
 .texts_container{
     width: 800px;
 }
 .btn_text{
     display: flex;
     gap: 100px;
     margin-top: 60px;
     justify-content: center;
 }
 .text2_container{
     font-size: 22px;
     line-height: 8px;
 }
 .text2_container h5{
     font-size: 20px;
     font-weight: 300;
     text-decoration: underline;
 }
 .quienes_somos_container h2{
     color: #021187; 
     font-size: 30px;
 }
 .quienes_somos_container p{
     font-size: 20px;
 }
 .quienes_somos_container h4{
     font-size: 20px;
 }
 .whatsapp_box{
     display: flex;
 }
 .whatsapp_box img{
     width: 50px;
     height: 50px;
     margin: 10px;
 }
 .inicio_btn{
     color: #021187;
     background: #2AFEB2;
     border-radius: 25px;
     height: 50px;
     line-height: 48px;
     width: 260px;
     box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.348);    
 }
 .inicio_btn:hover{
     transition: 0.5s;
     box-shadow: inset 7px 3px 3px rgba(0, 0, 0, 0.333);
 }
 
 @media (max-width: 1300px) {
     .img_catdog_box img{
         max-width: 100%;
     }
 }
 @media (max-width: 900px) {
     .final_step_container{
         flex-direction: column;
         align-items: center;
     }
     .texts_container{
         align-items: center;
         flex-direction: column;
         display: flex;
         max-width: 80%;
     }
     .text_banner{
         max-width: 80%;
         display: flex;
         flex-wrap: wrap;
     }
     .texts_container h2{
         font-size: 30px;
     }
     .texts_container p{
         font-size: 20px;
         width: 70%;
     }
     .text2_container h4{
         line-height: 20px;
     }
     .footer_container{
         display: flex;
         flex-direction: column;
     }
 }
 @media (max-width: 500px) {
     .btn_text{
         display: flex;
         flex-direction: column;
     }
     .quienes_somos_container p{
         font-size: 16px;
     }
     .quienes_somos_container h2{
         font-size: 23px;
     }
     .quienes_somos_container h4{
         font-size: 17px;
     }
 }