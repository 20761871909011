.quienes_somos_container{
    font-family: 'Poppins', sans-serif;
    margin-top: 100px;
}
.footer_container{
    background: #021187;
    text-align: left;
    color: white;
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
}
.footer_text_number{
    margin: 50px;
}
.footer_text_number h4{
    font-weight: 100;
}
.footer_container img{
    width: 200px;
}
.numbers_box{
    display: flex;
    color: white;
    line-height: 0;
    gap: 10px;
    margin-top: 15px;
}
.numbers_box img{
   width: 35px;
   height: 35px;
}
.ul_footer li{
    list-style: none;
    margin: 25px;
    font-size: 19px;
    color: white;
}
.ul_footer a{
    text-decoration: none;
}

@media (max-width: 500px){
    .ul_footer li{
        margin: 10px;
        font-size: 14px;
        width: 100px;
    }
}
@media (max-width: 340px){
    .quienes_somos_container img{
        width: 130px;
    }
}